import * as moment from 'moment';
import { CompanyInfo } from './../../_model/common/company-info.model';
import { Customer } from './../../_model/common/customer.model';
import { Service } from './../../_model/common/service.model';
import { WidgetAdditionaData } from './../../_model/common/widget-additiona-data';
import { WidgetInfo } from './../../_model/common/widget-info.model';
import { Coupon } from './../../_model/pgw/coupon';
import { ReservationInfo } from './../../_model/reservations/reservation-info.model';
import { Slot } from './../../_model/reservations/slot.model';
export namespace BaseStateActions {
    export namespace Reservations {
        export class SetReservationInfo {
            public static readonly type = '[reservations] SetReservationInfo';
            constructor(public readonly reservationInfo: ReservationInfo) {}
        }
        export class UpdateReservationInfo {
            public static readonly type = '[reservations] UpdateReservationToken';
            constructor(public readonly name: string, public readonly value: any) {}
        }
        export class Init {
            public static readonly type = '[reservations] Init';
            constructor(public readonly resParams: ReservationInfo, public readonly widgetInfo?: WidgetInfo | any) {}
        }

        export class SetReservationToken {
            public static readonly type = '[reservations] SetReservationToken';
            constructor(public readonly token: string) {}
        }
        export class GetGuidData {
            public static readonly type = '[reservations] GetGuidData';
            // constructor(public readonly guid: string) {}
        }

        export class ConfirmReservation {
            public static readonly type = '[reservations] ConfirmReservation';
            constructor() {}
        }

        export class CreateCustomerAndBookSlot {
            public static readonly type = '[reservations] CreateCustomerAndBookSlot';
            constructor(public readonly additionaData?: WidgetAdditionaData) {}
        }

        export class UploadDocumentation {
            public static readonly type = '[reservations] UploadDocumentation';
            constructor(public readonly documentation?: File[]) {}
        }
    }

    export namespace Widget {
        // export class Info {
        //     public static readonly type = '[widget] Init';
        //     constructor(public readonly resParams: ReservationInfo) {}
        // }

        export class Init {
            public static readonly type = '[widget] Init';
            constructor(public readonly widgetInfo: WidgetInfo) {}
        }

        // export class Init {
        //     public static readonly type = '[widget] Init';
        //     constructor(public readonly resParams: ReservationInfo) {}
        // }

        export class GetWidgetData {
            public static readonly type = '[widget] GetWidgetData';
            // constructor(public readonly from: moment.Moment, public readonly to: moment.Moment) {}
        }

        export class CreateReservation {
            public static readonly type = '[widget] CreateCustomer';
            constructor(public readonly customer?: Customer, public readonly pgw?: boolean, public readonly additionaData?: WidgetAdditionaData) {}
        }

        export class AddToWaitingQueue {
            public static readonly type = '[widget] AddToWaitingQueue';
            constructor(public readonly customer?: Customer) {}
        }

        export class SetCustomer {
            public static readonly type = '[widget] SetCustomer';
            constructor(public readonly customer?: Customer) {}
        }

        export class ConfirmReservation {
            public static readonly type = '[widget] ConfirmReservation';
            constructor(
                public readonly guid: string,
                public readonly isExternal: boolean = false,
                public readonly isAfterOnlinePayment: boolean = false,
            ) {}
        }

        export class SetService {
            public static readonly type = '[widget] SetService';
            constructor(public readonly service: Service) {}
        }

        // export class ToogleServiceGroup {
        //     public static readonly type = '[common] toogleServiceGroup';
        //     constructor(public readonly serviceGroup: ServiceGroup) {}
        // }
    }

    export namespace Common {
        export class GetSlotsData {
            public static readonly type = '[common] GetReservationData';
            constructor(
                public readonly start: moment.Moment,
                public readonly end: moment.Moment,
                public readonly findFreeSlot: boolean = false,
                public readonly dayIndex: number = 7,
            ) {}
        }
        export class ClearReservationData {
            public static readonly type = '[common] ClearReservationData';
        }

        export class OnSelectedSlot {
            public static readonly type = '[common] OnSelectedSlot';
            constructor(public readonly slot: Slot) {}
        }

        export class OnSelectedWQ {
            public static readonly type = '[common] OnSelectedWQ';
            constructor() {}
        }

        export class BookSelectedSlot {
            public static readonly type = '[common] BookSelectedSlot';
            constructor(public readonly slot?: Slot, public readonly additionaData?: WidgetAdditionaData) {}
        }

        // export class ConfirmReservation {
        //     public static readonly type = '[common] ConfirmReservation';
        // }

        export class SetPrivacyPolicy {
            public static readonly type = '[common] SetPrivacyPolicys';
            constructor(public readonly policy: boolean) {}
        }

        export class ClearBaseState {
            public static readonly type = '[common] ClearBaseState';
        }

        export class SetDateRange {
            public static readonly type = '[common] SetDateRange';
            constructor(public readonly start: moment.Moment, public readonly end: moment.Moment) {}
        }

        export class SetSelecteSlot {
            public static readonly type = '[common] SetSelecteSlot';
            constructor(public readonly selectedSlot?: Slot) {}
        }

        export class SetConfirmationSend {
            public static readonly type = '[common] SetConfirmationSend';
            constructor(public readonly isConfirmationSend: boolean) {}
        }

        // export class OnSelectedSlot {
        //     public static readonly type = '[common] SetSelectedSlot';
        //     constructor(public readonly slot: Slot) {}
        // }

        export class GetContractor {
            public static readonly type = '[common] GetContractor';
            constructor() {}
        }

        export class SetAccessToken {
            public static readonly type = '[common] SetAccessToken';
            constructor(public readonly token: string) {}
        }

        export class SetCoupon {
            public static readonly type = '[common] SetCoupon';
            constructor(public readonly coupon: Coupon) {}
        }

        export class SetCompanyInfo {
            public static readonly type = '[common] SetCompanyInfo';
            constructor(public readonly ci: CompanyInfo) {}
        }

        export class ExecutePayment {
            public static readonly type = '[common] ExecutePayment';
            constructor(public readonly customer?: Customer) {}
        }
    }
}
