/* eslint-disable no-empty-pattern */
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EMPTY, forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PaymentProviderType } from '../../_enums/payment-provider.enum';
import { CustomerInfoDto } from '../../_model/pgw/customer-info-dto';
import { ExecutePaymentRequestDto } from '../../_model/pgw/execute-payment-request-dto';
import { ExecutePaymentResponseDto } from '../../_model/pgw/execute-payment-response-dto';
import { PaymentRedirectInfoDto } from '../../_model/pgw/payment-redirect-info-dto';
import { ParentIframeService } from '../../_services/parent-iframe.service';
import { environment } from './../../../environments/environment';
import { WidCommonService } from './../../modules/widget/services/wid-common.service';
import { ModeType } from './../../_enums/mode-type.enum';
import { ReservationType } from './../../_enums/reservationType.enum';
import { WidgetType } from './../../_enums/widgetType.enum';
import { CompanyInfo } from './../../_model/common/company-info.model';
import { Contractor } from './../../_model/common/contractor.model';
import { Customer } from './../../_model/common/customer.model';
import { DateRange } from './../../_model/common/date-range.model';
import { Document } from './../../_model/common/document-response';
import { GuidInfo } from './../../_model/common/guid-info.model';
import { Service } from './../../_model/common/service.model';
import { WidgetData } from './../../_model/common/widget-data.model';
import { WidgetInfo } from './../../_model/common/widget-info.model';
import { PaymentInfo } from './../../_model/pgw/payment-info.model';
import { DaySlot } from './../../_model/reservations/day-slot.model';
import { MaskedPrereservationRequest } from './../../_model/reservations/masked-prereservation-request.model';
import { PriceList } from './../../_model/reservations/price-list.model';
import { ReservationConfirmationRequest } from './../../_model/reservations/reservation-confirmation-request.model';
import { ReservationInfo } from './../../_model/reservations/reservation-info.model';
import { Slot } from './../../_model/reservations/slot.model';
import { SubcontractorGroup } from './../../_model/reservations/subcontractor-group.model';
import { WqRequest } from './../../_model/reservations/wqRequest';
import { CommonService } from './../../_services/common.service';
import { HelperService } from './../../_services/helper.service';
import { MfToasterService } from './../../_services/mf-toaster.service';
import { PgwService } from './../../_services/pgw.service';
import { ReservationsService } from './../../_services/reservations.service';
import { BaseStateModel } from './base-state.model';
import { BaseStateActions } from './base.actions';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    modeType: undefined,
    reservationInfo: undefined,
    // resParams: undefined,
    subcontractorGroup: undefined,
    slots: undefined,
    selectedSlot: undefined,
    customer: undefined,
    isPrivacyPolicy: false,
    contractor: undefined,
    prereservation: undefined,
    widgetInfo: undefined,
    dateRange: undefined,
    accessToken: undefined,
    companyInfo: undefined,
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class BaseState {
    constructor(
        private reservationsService: ReservationsService,
        private store: Store,
        private comonRest: CommonService,
        private pgw: PgwService,
        private helper: HelperService,
        private toast: MfToasterService,
        private widgetRest: WidCommonService,
        private parentIframe: ParentIframeService,
    ) {}

    @Selector([BASESTATE_TOKEN])
    public static getAccessToken(state: BaseStateModel): string {
        return state.accessToken;
    }

    @Selector([BASESTATE_TOKEN])
    public static getWidgetInfo(state: BaseStateModel): WidgetInfo {
        return state.widgetInfo;
    }

    @Selector([BASESTATE_TOKEN])
    public static getModeType(state: BaseStateModel): ModeType {
        return state.modeType;
    }

    @Selector([BASESTATE_TOKEN])
    public static getReservationInfo(state: BaseStateModel): ReservationInfo {
        return state.reservationInfo;
    }

    @Selector([BASESTATE_TOKEN])
    public static getPreReservation(state: BaseStateModel): ReservationConfirmationRequest {
        return state.prereservation;
    }

    @Selector([BASESTATE_TOKEN])
    public static getCompanyInfo(state: BaseStateModel): CompanyInfo {
        return state.companyInfo;
    }

    @Selector([BASESTATE_TOKEN])
    public static getSelectedSlot(state: BaseStateModel): Slot {
        return state.selectedSlot;
    }

    @Selector([BASESTATE_TOKEN])
    public static getCustomer(state: BaseStateModel): Customer {
        return state.customer;
    }

    // @Selector([BASESTATE_TOKEN])
    // public static getReservationParams(state: BaseStateModel): ResSelectSlotResult {
    //     return state.resParams;
    // }

    @Selector([BASESTATE_TOKEN])
    public static getSlots(state: BaseStateModel): SubcontractorGroup[] {
        return state.subcontractorGroup;
    }

    // @Selector([BASESTATE_TOKEN])
    // public static getSlots(state: BaseStateModel): DaySlot[] {
    //     return state.slots;
    // }

    @Selector([BASESTATE_TOKEN])
    public static getAllReservationData(state: BaseStateModel): any {
        return {
            reservationInfo: state.reservationInfo,
            subcontractorGroup: state.subcontractorGroup,
            widgetInfo: state.widgetInfo,
            dateRange: state.dateRange,
        };
    }

    @Selector([BASESTATE_TOKEN])
    public static getPrivacyPolicy(state: BaseStateModel): boolean {
        return state.isPrivacyPolicy;
    }

    @Action(BaseStateActions.Common.ClearBaseState)
    public ClearState(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.ClearBaseState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(BaseStateActions.Common.SetDateRange)
    public SetDateRange(ctx: StateContext<BaseStateModel>, { start, end }: BaseStateActions.Common.SetDateRange) {
        ctx.patchState({
            dateRange: {
                start: start,
                end: end,
            },
        });
    }

    @Action(BaseStateActions.Common.SetConfirmationSend)
    public SetConfirmationSend(ctx: StateContext<BaseStateModel>, { isConfirmationSend }: BaseStateActions.Common.SetConfirmationSend) {
        ctx.setState(
            patch<BaseStateModel>({
                selectedSlot: patch({
                    isConfirmationSend: isConfirmationSend,
                }),
            }),
        );
    }

    @Action(BaseStateActions.Common.SetSelecteSlot)
    public SetSelecteSlot(ctx: StateContext<BaseStateModel>, { selectedSlot }: BaseStateActions.Common.SetSelecteSlot) {
        ctx.patchState({
            selectedSlot: selectedSlot,
        });
    }

    @Action(BaseStateActions.Common.SetAccessToken)
    public SetAccessToken(ctx: StateContext<BaseStateModel>, { token }: BaseStateActions.Common.SetAccessToken) {
        ctx.patchState({
            accessToken: token,
        });
    }

    @Action(BaseStateActions.Reservations.SetReservationInfo)
    public SetReservationInfo(ctx: StateContext<BaseStateModel>, { reservationInfo }: BaseStateActions.Reservations.SetReservationInfo) {
        ctx.patchState({ reservationInfo: reservationInfo });
    }

    @Action(BaseStateActions.Reservations.Init)
    public InitReservation(ctx: StateContext<BaseStateModel>, { resParams, widgetInfo }: BaseStateActions.Reservations.Init) {
        if (widgetInfo) {
            if (widgetInfo?.data && this.helper.isJson(widgetInfo?.data)) {
                widgetInfo.data = JSON.parse(<string>widgetInfo?.data);
            }
        }

        if (resParams.service.directBooking == undefined) {
            resParams.service.directBooking = true;
        }

        ctx.patchState({ reservationInfo: resParams, modeType: ModeType.RESERVATION, widgetInfo: widgetInfo });
    }

    @Action(BaseStateActions.Reservations.SetReservationToken)
    public SetReservationToken(ctx: StateContext<BaseStateModel>, { token }: BaseStateActions.Reservations.SetReservationToken) {
        ctx.setState(
            patch<BaseStateModel>({
                reservationInfo: patch({
                    guid: token,
                }),
            }),
        );
    }

    @Action(BaseStateActions.Reservations.UpdateReservationInfo)
    public UpdateReservationInfo(ctx: StateContext<BaseStateModel>, { name, value }: BaseStateActions.Reservations.UpdateReservationInfo) {
        ctx.setState(
            patch<BaseStateModel>({
                reservationInfo: patch({ [name]: val => (val = value) }),
            }),
        );
    }

    @Action(BaseStateActions.Common.GetSlotsData)
    public GetSlotsData(
        ctx: StateContext<BaseStateModel>,
        { start, end, findFreeSlot, dayIndex }: BaseStateActions.Common.GetSlotsData,
    ): Observable<any> {
        const resinf: ReservationInfo = ctx.getState().reservationInfo;
        const accessToken: string = ctx.getState().accessToken;
        if (findFreeSlot) {
            start = start.clone().add(dayIndex, 'days');
            end = end.clone().add(dayIndex - 1, 'days');
        }
        // debugger;
        let slotsRest: Observable<Slot[]> = EMPTY;
        let priceList: Observable<PriceList[]> = this.comonRest.getSubcontractorServicePrices(resinf.maskedContractorId, resinf.maskedServiceId);
        // sedaj dobim ceno že pri nalaganju widgeta
        // let externalPrice: Observable<number> = this.comonRest.getBSServicePrice(
        //     resinf?.maskedBsId,
        //     resinf?.maskedContractorId,
        //     resinf?.maskedServiceId,
        // );
        const apiCall = {
            freeSlotsWidget: this.reservationsService.getFreeSlotsWidget(
                resinf?.maskedContractorId,
                resinf?.maskedSubcontractorId,
                resinf?.maskedServiceId,
                start,
                end,
                findFreeSlot,
            ),
            freeSlotsBS: this.reservationsService.getFreeSlotsBs(resinf.bsId, resinf.token, start, end, findFreeSlot, accessToken),
            freeSlotsWidgetExternal: this.reservationsService.getFreeSlotsWidgetExternal(
                resinf?.maskedBsId,
                resinf.maskedContractorInfoId ? resinf.maskedContractorInfoId : resinf?.maskedContractorId,
                encodeURIComponent(resinf.maskedServiceId),
                start,
                end,
                findFreeSlot,
                resinf?.maskedSubcontractorId ? encodeURIComponent(resinf?.maskedSubcontractorId) : undefined,
                resinf?.orgUnitId,
            ),

            // freeSlotsWidgetExternal: this.reservationsService.getFreeSlotsWidgetExternal(
            //     resinf?.maskedBsId,
            //     resinf?.maskedContractorId,
            //     _.get(resinf, 'externalServices[0].externalMaskedId'),
            //     start,
            //     end,
            //     findFreeSlot,
            // ),
        };
        if (resinf?.bsId && accessToken) {
            // externalPrice = of(undefined);
            slotsRest = apiCall.freeSlotsBS;
        } else if (!resinf?.bsId && !resinf.isExternal) {
            // externalPrice = of(undefined);
            slotsRest = apiCall.freeSlotsWidget;
        } else {
            priceList = of(undefined);
            slotsRest = apiCall.freeSlotsWidgetExternal;
        }

        return forkJoin({
            priceList: priceList,
            slots: slotsRest,
            // externalPrice: externalPrice,
        }).pipe(
            map(
                ({
                    priceList,
                    slots,
                }: // externalPrice,
                {
                    priceList: PriceList[];
                    slots: Slot[];
                    // externalPrice: number;
                }) => {
                    // return throwError(new Error('no-free-slots'));
                    const dr: DateRange = {
                        start: start,
                        end: end,
                    };
                    if (findFreeSlot) {
                        //skok na prvi termin
                        if (slots?.length > 0) {
                            dr.start = moment(slots[0]?.start);
                            dr.end = moment(slots[0]?.start).clone().add(4, 'days');
                            // .add(dayIndex - 1, 'days');
                        }
                    }
                    ctx.setState(
                        patch<BaseStateModel>({
                            dateRange: dr,
                            subcontractorGroup: this.manageSlots(slots, dr, priceList, resinf?.service?.maxPrice),
                            // slots: this.manageSlots(slots),
                        }),
                    );
                },
            ),
            catchError(err => {
                ctx.setState(
                    patch<BaseStateModel>({
                        // dateRange: dr,
                        subcontractorGroup: null,
                        // slots: this.manageSlots(slots),
                    }),
                );
                return throwError(err);
            }),
        );
    }

    @Action(BaseStateActions.Reservations.GetGuidData)
    public GetReservationSlotsData(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Reservations.GetGuidData): Observable<any> {
        const guid: string = ctx.getState().reservationInfo.guid;
        return this.reservationsService.getTokenInfo(guid).pipe(
            tap((reservationInfo: ReservationInfo) => {
                ctx.setState(
                    patch<BaseStateModel>({
                        reservationInfo: patch<any>(reservationInfo),
                        customer: new Customer().deserialize(reservationInfo.customer),
                    }),
                );
            }),
        );
    }

    private manageSlots(slots: Slot[], dr: DateRange, priceList: PriceList[], externalPrice: number): SubcontractorGroup[] {
        const group: any = _.groupBy(slots, 'maskedSubcontractorId');
        const groupPriceList: any = _.groupBy(priceList, 'maskedSubcontractorId');
        const subcontracotrGroup: SubcontractorGroup[] = [];
        const wi = this.store.selectSnapshot(BaseState.getWidgetInfo);

        const parsedSlots: DaySlot[] = [];
        //Pripravimo vse datume za katere smo sli iskat podatke
        for (const m = dr.start.clone(); m.isSameOrBefore(dr.end, 'day'); m.add(1, 'days')) {
            if (wi?.data && (<WidgetData>wi?.data).showWeekend === false) {
                if (m.days() != 0 && m.days() != 6) {
                    parsedSlots.push(
                        new DaySlot().deserialize({
                            date: m.clone(),
                            slots: [],
                        }),
                    );
                }
            } else {
                parsedSlots.push(
                    new DaySlot().deserialize({
                        date: m.clone(),
                        slots: [],
                    }),
                );
            }
        }
        for (const subcontractorId in group) {
            let hasSomeSlots = false;
            group[subcontractorId].forEach((slot: Slot) => {
                const slotStart = moment(slot.start);
                //TODO

                const unit: any = wi?.data?.slotsAfter?.unit ?? 'days';
                const amount = wi?.data?.slotsAfter?.amount ?? 1;

                const dateValidFrom = moment().add(amount, unit);
                const isAfterValidDate = slotStart > dateValidFrom;
                if (isAfterValidDate) {
                    const day: moment.Moment = moment(slot.start); //.format('YYYY-MM-DD');
                    const index = parsedSlots.findIndex((item: any) => {
                        return item.date.isSame(day, 'date');
                    });
                    if (index > -1) {
                        hasSomeSlots = true;
                        parsedSlots[index].slots.push(slot);
                    }
                }
            });
            if (hasSomeSlots) {
                subcontracotrGroup.push(
                    new SubcontractorGroup().deserialize({
                        subcontractorId: group[subcontractorId][0]?.subcontractorId,
                        subcontractorName: group[subcontractorId][0]?.subcontractorName,
                        subcontractorTitle: group[subcontractorId][0]?.subcontractorTitle,
                        price: groupPriceList[subcontractorId] ? groupPriceList[subcontractorId][0]?.price : externalPrice,
                        daySlot: _.cloneDeep(parsedSlots),
                        hash: groupPriceList[subcontractorId] ? groupPriceList[subcontractorId][0].hash : undefined,
                    }),
                );
            }
            //reset template
            hasSomeSlots = false;
            parsedSlots.map(el => (el.slots = []));
        }

        return subcontracotrGroup;
    }

    @Action(BaseStateActions.Widget.Init)
    public InitWidget(ctx: StateContext<BaseStateModel>, { widgetInfo }: BaseStateActions.Widget.Init) {
        if (widgetInfo?.data && this.helper.isJson(widgetInfo?.data)) {
            widgetInfo.data = <WidgetData>JSON.parse(<string>widgetInfo.data);
        }
        // debugger;
        // let service = widgetInfo.externalServices
        //     ? new Service().deserialize({
        //           name: widgetInfo.externalServices[0]?.name,
        //           id: widgetInfo.externalServices[0]?.externalMaskedId,
        //       })
        //     : undefined;
        return iif(() => widgetInfo?.data?.widgetId == undefined, of(undefined), this.widgetRest.getWidgetData(widgetInfo?.data?.widgetId)).pipe(
            tap(widgetTemplate => {
                //ce ima widget nastavljen template uporabimo tega
                if (widgetTemplate?.data && this.helper.isJson(widgetTemplate?.data)) {
                    //zamenjamo samo nastavljene podatke
                    widgetInfo.data = { ...widgetInfo.data, ...(<WidgetData>JSON.parse(<string>widgetTemplate.data)) };
                }
                //počasi prenesel vse iz widgetInfo v  reservatonInfo
                let service: Service;
                if (widgetInfo?.externalServices) {
                    const exService = widgetInfo.externalServices[0];
                    service = {
                        ...exService,
                        id: exService?.externalMaskedId,
                    };
                } else {
                    service = {
                        name: widgetInfo?.serviceName,
                        id: widgetInfo?.maskedServiceId,
                        directBooking: true,
                    };
                }

                if (widgetInfo?.services) {
                    //ce pride iz ea je vse direkt
                    widgetInfo.services.map(ser => (ser.directBooking = true));
                }

                if (widgetInfo?.serviceGroups) {
                    //ce pride iz ea je vse direkt
                    widgetInfo.serviceGroups.map(group => {
                        group.services.map(ser => {
                            ser.directBooking = true;
                        });
                    });
                }

                const reservationInfo: ReservationInfo = {
                    maskedContractorId: widgetInfo?.maskedContractorId,
                    maskedServiceId: widgetInfo?.maskedServiceId || decodeURIComponent(_.get(widgetInfo, 'externalServices[0].externalMaskedId')),
                    maskedSubcontractorId: widgetInfo?.maskedSubcontractorId,
                    services: widgetInfo.services,
                    service: service,
                    externalServices: widgetInfo.externalServices,
                    externalServiceGroups: widgetInfo.externalServiceGroups,
                    isExternal: widgetInfo?.isExternal,
                    maskedBsId: widgetInfo?.maskedBsId,
                    widgetId: widgetInfo?.id,
                    orgUnitId: widgetInfo?.orgUnitId,
                };

                // if (widgetInfo.widgetType == WidgetType.CONTRACTOR_RATING) {
                ctx.patchState({
                    widgetInfo: widgetInfo,
                    modeType: ModeType.WIDGET,
                    reservationInfo: reservationInfo,
                });
            }),
        );
    }

    @Action(BaseStateActions.Widget.GetWidgetData)
    public GetWidgetSlotsData(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Widget.GetWidgetData) {
        const params: WidgetInfo = ctx.getState().widgetInfo;
        if (params.widgetType == WidgetType.ADVANCED_SELF_BOOKING) {
            // Empty
        } else {
            return this.reservationsService
                .getSubcontractorServiceInfo(params.maskedContractorId, params.maskedSubcontractorId, params.maskedServiceId)
                .pipe(
                    tap((service: Service) => {
                        ctx.setState(
                            patch<BaseStateModel>({
                                reservationInfo: patch({
                                    service: service,
                                }),
                            }),
                        );
                    }),
                );
        }
    }

    @Action(BaseStateActions.Reservations.ConfirmReservation)
    public ReservationConfirmReservation(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Reservations.ConfirmReservation) {
        const preres: ReservationConfirmationRequest = ctx.getState().prereservation;
        const resinfo: ReservationInfo = ctx.getState().reservationInfo;
        //confirmPrereservation
        if (resinfo.isExternal) {
            return this.reservationsService.widgetVendorConfirmPrereservation(preres, resinfo?.token).pipe(catchError((err: any) => throwError(err)));
        }
        //potrjevanje za ea
        return this.reservationsService
            .confirmCustomerPrereservation(preres.prereservationId, resinfo?.token)
            .pipe(catchError((err: any) => throwError(err)));
    }

    @Action(BaseStateActions.Reservations.CreateCustomerAndBookSlot)
    public CreateCustomerAndBookSlot(ctx: StateContext<BaseStateModel>, { additionaData }: BaseStateActions.Reservations.CreateCustomerAndBookSlot) {
        // const preres: ReservationConfirmationRequest = ctx.getState().prereservation;
        const resinfo: ReservationInfo = ctx.getState().reservationInfo;

        return this.comonRest.addCustomer(resinfo.maskedContractorId, resinfo.customer).pipe(
            tap(maskedCustomerId => {
                this.store.dispatch([
                    new BaseStateActions.Reservations.UpdateReservationInfo('maskedCustomerId', maskedCustomerId),
                    new BaseStateActions.Common.BookSelectedSlot(ctx.getState().selectedSlot, additionaData),
                ]);
            }),
        );
    }

    @Action(BaseStateActions.Reservations.UploadDocumentation)
    public UploadDocumentation(ctx: StateContext<BaseStateModel>, { documentation }: BaseStateActions.Reservations.UploadDocumentation) {
        return this.reservationsService.uploadDocuments(documentation).pipe(
            filter(event => event.type === HttpEventType.Response),
            mergeMap((event: HttpResponse<any>) => {
                const returnData = <Document>this.helper.safeParseJson(event.body)?.documents;
                return this.store.dispatch(new BaseStateActions.Reservations.UpdateReservationInfo('documents', returnData));
            }),
        );
    }

    @Action(BaseStateActions.Widget.ConfirmReservation)
    public WidgetConfirmReservation(
        ctx: StateContext<BaseStateModel>,
        { guid, isExternal, isAfterOnlinePayment }: BaseStateActions.Widget.ConfirmReservation,
    ) {
        return this.comonRest.getGuidInfo(guid, false, isExternal).pipe(
            mergeMap(info => {
                if (info?.widgetId) {
                    return this.widgetRest.getWidgetData(info.widgetId).pipe(
                        mergeMap((widgetInfo: WidgetInfo) => {
                            return this.store.dispatch(new BaseStateActions.Widget.Init(widgetInfo)).pipe(map(() => info));
                        }),
                    );
                }
                return of(info);
            }),
            tap((info: GuidInfo) => {
                const data: ReservationInfo = {
                    service: {
                        name: info.serviceName,
                        directBooking: true,
                    },
                    contractor: new Contractor().deserialize({
                        name: info.contractorName,
                    }),
                    startDate: moment(info.reservationFrom),
                };
                ctx.setState(
                    patch<BaseStateModel>({
                        reservationInfo: {
                            service: data.service,
                            contractor: data.contractor,
                            startDate: data.startDate,
                        },
                    }),
                );
                ctx.patchState({ reservationInfo: data });
            }),
            mergeMap(() => {
                if (!isAfterOnlinePayment) {
                    return this.reservationsService.confirmCustomerPrereservation(guid);
                }
                return of(null);
            }),
        );
        // .subscribe();
    }

    @Action(BaseStateActions.Common.ClearReservationData)
    public ClearReservationData(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.ClearReservationData) {
        ctx.setState(
            patch<BaseStateModel>({
                slots: undefined,
                // reservationInfo: undefined,
            }),
        );
    }

    @Action(BaseStateActions.Widget.AddToWaitingQueue)
    public AddToWaitingQueue(ctx: StateContext<BaseStateModel>, { customer }: BaseStateActions.Widget.AddToWaitingQueue) {
        // if (ctx.getState().customer?.maskedId) {
        //     createCustomer = of(this.store.selectSnapshot(BaseState.getCustomer)?.maskedId);
        //     ctx.setState(
        //         patch<BaseStateModel>({
        //             customer: customer,
        //         }),
        //     );
        // }
        this.helper.showLoading();
        const reservationInfo: ReservationInfo = ctx.getState().reservationInfo;
        const slot = ctx.getState().selectedSlot;
        const params: WidgetInfo = ctx.getState().widgetInfo;
        const wqRequest: WqRequest = {
            maskedContractorId: reservationInfo.maskedContractorId,
            maskedSubcontractorId: reservationInfo?.maskedSubcontractorId,
            maskedServiceId: reservationInfo.maskedServiceId,
            originUrl: params.origin,
            telemed: reservationInfo?.service?.telemed,
            phoneCall: reservationInfo?.service?.phoneCall,
            comment: this.getComment(reservationInfo?.comment),
            customer: customer,
            maskedBsId: reservationInfo.maskedBsId,
            widgetId: reservationInfo?.widgetId,
            servicePrice: slot?.price,
            orderedServices: [
                {
                    name: reservationInfo?.service?.name,
                    price: slot?.price,
                },
            ],
        };
        return this.reservationsService.createMaskedWaitingQueue(wqRequest).pipe(
            tap(
                () => {
                    this.helper.hideLoading();
                    this.store.dispatch(
                        new Navigate(['/public/booking-widget/waiting-queue/confirmed'], undefined, {
                            queryParamsHandling: 'merge',
                            replaceUrl: true,
                        }),
                    );
                },
                () => {
                    this.helper.hideLoading();
                },
            ),
        );
    }

    @Action(BaseStateActions.Widget.CreateReservation)
    public CreateReservation(ctx: StateContext<BaseStateModel>, { customer, pgw, additionaData }: BaseStateActions.Widget.CreateReservation) {
        this.helper.showLoading();
        let createCustomer: Observable<any>;
        if (!customer) {
            customer = _.cloneDeep(ctx.getState().customer);
        }
        // const customerStore:Customer = ctx.getState().customer;
        // const contractor: Contractor = ctx.getState().contractor;
        const params: WidgetInfo = ctx.getState().widgetInfo;
        const reservationInfo: ReservationInfo = ctx.getState().reservationInfo;
        const selectedSlot: Slot = ctx.getState().selectedSlot;
        if (selectedSlot.isConfirmationSend) {
            return throwError({ message: 'confirmation already sent', code: 'confirmationAlreadySent' });
        }
        if (ctx.getState().customer?.maskedId) {
            createCustomer = of(this.store.selectSnapshot(BaseState.getCustomer)?.maskedId);
            ctx.setState(
                patch<BaseStateModel>({
                    customer: customer,
                }),
            );
        } else if (!reservationInfo.isExternal) {
            //če stranke še ni v state-u jo dodamo

            createCustomer = this.comonRest.addCustomer(params.maskedContractorId, customer).pipe(
                tap((maskedId: string) => {
                    customer.maskedId = maskedId;
                    ctx.setState(
                        patch<BaseStateModel>({
                            customer: customer,
                        }),
                    );
                }),
            );
        } else {
            createCustomer = of(undefined);
            // ctx.setState(
            //     patch<BaseStateModel>({
            //         customer: customer,
            //     }),
            // );
        }
        return createCustomer
            .pipe(
                // catchError((err) => {
                //     this.toast.error('Napaka pri potrjevanju termina.');
                //     this.helper.hideLoading();
                //     return throwError(err);
                // }),
                mergeMap((maskedCustomerId: string) => {
                    const request: MaskedPrereservationRequest = {
                        // Start HF MD-3348
                        maskedContractorId: params?.maskedCinfoId || reservationInfo?.maskedCinfoId,
                        maskedCinfoId: params?.maskedCinfoId || reservationInfo?.maskedCinfoId,
                        // End HF
                        maskedSubcontractorId: selectedSlot.maskedSubcontractorId,
                        maskedServiceId: reservationInfo.maskedServiceId,
                        maskedCustomerId: maskedCustomerId,
                        // asset: { id: selectedSlot.assetId },
                        from: selectedSlot.start,
                        to: selectedSlot.end,
                        forceUpdate: false,
                        originUrl: params.origin,
                        reservationType: ReservationType.WIDGET,
                        telemed: reservationInfo?.service?.telemed,
                        phoneCall: reservationInfo?.service?.phoneCall,
                        comment: this.getComment(reservationInfo?.comment),
                        customer: customer, //TODO
                        maskedBsId: reservationInfo.maskedBsId, //TODO
                        scheduleId: selectedSlot?.scheduleId,
                        service: {
                            name: reservationInfo?.service?.name,
                            price: selectedSlot?.price,
                            orgUnit: {
                                externalId: reservationInfo?.orgUnitId,
                            },
                        },
                        doZdravnika: reservationInfo?.doZdravnika,
                        widgetId: reservationInfo?.widgetId,
                        widgetData: JSON.stringify(additionaData),
                        allowHolidayBooking: true,
                        documents: { documents: reservationInfo?.documents },
                        noOfReservations: 1, // TODO hardcoded & apply only for heliant bpi
                    };
                    if (selectedSlot?.assetId != undefined && selectedSlot?.assetId != -1) {
                        request.asset = {
                            id: selectedSlot.assetId,
                            externalId: reservationInfo.service?.externalSubcontractor?.orgUnitId ?? selectedSlot.assetId, //TODO preveri ce je tole ok za X21
                        };
                    }

                    return this.reservationsService.createMaskedPrereservation(request).pipe(
                        mergeMap((reservation: ReservationConfirmationRequest) => {
                            if (pgw) {
                                reservation.onlinePayment = true;
                            }
                            if (reservationInfo.isExternal) {
                                reservation.maskedBsId = reservationInfo.maskedBsId;
                                reservation.maskedContractorId = reservationInfo.maskedContractorId;
                                return this.reservationsService.widgetVendorConfirmPrereservation(reservation).pipe(
                                    // catchError((err) => {
                                    //     this.toast.error('Napaka pri potrjevanju termina.');

                                    //     this.helper.hideLoading();
                                    //     return throwError(err);
                                    // }),
                                    mergeMap(() => {
                                        if (!pgw) {
                                            //ce ni placilnega modula
                                            return of({ url: 'skipConfirmation' });
                                        }

                                        ctx.setState(
                                            patch<BaseStateModel>({
                                                prereservation: reservation,
                                            }),
                                        );
                                        return this.payment(
                                            selectedSlot?.discountValue == undefined ? selectedSlot?.price : selectedSlot?.discountValue,
                                            customer,
                                            reservation.prereservationId,
                                        );

                                        return of({ url: 'skipConfirmation' });
                                        // if (!params?.pgw) {
                                        //     //ce ni placilnega modula
                                        // return of(false);

                                        // }

                                        // return this.payment(
                                        //     ctx.getState().selectedSlot?.price,
                                        //     customer,
                                        //     reservation.prereservationId,
                                        // );
                                    }),
                                );
                            }

                            return this.reservationsService.widgetConfirmPrereservation(reservation, !params?.data?.autoConfirmReservation).pipe(
                                mergeMap(() => {
                                    ctx.setState(
                                        patch<BaseStateModel>({
                                            prereservation: reservation,
                                        }),
                                    );
                                    if (!pgw) {
                                        if (params?.data?.autoConfirmReservation) {
                                            // potrditvenega mejla
                                            return this.store
                                                .dispatch(new BaseStateActions.Widget.ConfirmReservation(reservation?.prereservationId))
                                                .pipe(
                                                    map(() => {
                                                        return { url: 'skipConfirmation' };
                                                    }),
                                                );
                                        }
                                        //todo zapomni se da je že potrjeno
                                        //ce ni placilnega modula
                                        return of({ url: 'noPayment' });
                                    } else {
                                        return this.payment(
                                            selectedSlot?.discountValue == undefined ? selectedSlot?.price : selectedSlot?.discountValue,
                                            customer,
                                            reservation.prereservationId,
                                        );
                                    }
                                }),
                            );
                        }),
                    );
                }),
            )
            .pipe(
                map(
                    (action: ExecutePaymentResponseDto) => {
                        this.helper.hideLoading();
                        switch (action?.url) {
                            case 'paid':
                                //TODO preveri če se je ta termin že potrdil oz. poslal email
                                //ce je placano preskocis
                                this.store.dispatch(
                                    new Navigate(['public/booking-widget/confirmed'], {
                                        guid: ctx.getState().prereservation?.prereservationId,
                                    }),
                                );
                                break;
                            case 'skipConfirmation':
                                this.store.dispatch(
                                    new Navigate(['/public/booking-widget/confirmed'], undefined, {
                                        queryParamsHandling: 'merge',
                                        replaceUrl: true,
                                    }),
                                );
                                break;
                            case 'noPayment':
                                //ce je payment
                                this.store.dispatch(
                                    new Navigate(['public/booking-widget/review'], undefined, {
                                        queryParamsHandling: 'merge',
                                        replaceUrl: true,
                                    }),
                                );
                                break;

                            default:
                                break;
                        }
                    },

                    err => {
                        // const t: string = _.get(err, 'error.errors[0].msg', '{}');
                        // let split = [];
                        // if (t.indexOf('500') > 0) {
                        //     split = t.split('500 :');
                        // } else {
                        //     split = t.split('400 :');
                        // }
                        // if (split?.length > 0) {
                        //     let jsonError = '';
                        //     try {
                        //         jsonError = JSON.parse(split[1]);
                        //     } catch (e) {}
                        //     this.toast.error(_.get(jsonError, '[0].message'), true);
                        // } else {
                        //     this.toast.error('Napaka pri potrjevanju termina', true);
                        // }
                        // this.helper.hideLoading();
                        // setTimeout(() => {
                        // }, 500);
                    },
                ),
            );
        // .subscribe(
        //     (action: PaymentResult) => {

        //         // if (action?.url == 'paid') {
        //         //     //ce je placano preskocis
        //         //     this.store.dispatch(
        //         //         new Navigate(['public/booking-widget/confirmed'], {
        //         //             guid: ctx.getState().prereservation?.prereservationId,
        //         //         }),
        //         //     );
        //         // } else if (action?.url == 'noPayment') {
        //         //     //ce je payment
        //         //     this.store.dispatch(new Navigate(['public/booking-widget/review']));
        //         // } else {
        //         //     this.store.dispatch(new Navigate(['/public/booking-widget/confirmed']));
        //         // }
        //     },
        //     (err) => {
        //         const t: string = _.get(err, 'error.errors[0].msg', '{}');
        //         let split = [];
        //         if (t.indexOf('500') > 0) {
        //             split = t.split('500 :');
        //         } else {
        //             split = t.split('400 :');
        //         }

        //         if (split?.length > 0) {
        //             let jsonError = '';
        //             try {
        //                 jsonError = JSON.parse(split[1]);
        //             } catch (e) {}
        //             this.toast.error(_.get(jsonError, '[0].message'), true);
        //         } else {
        //             this.toast.error('Napaka pri potrjevanju termina', true);
        //         }
        //         this.helper.hideLoading();
        //         // setTimeout(() => {
        //         // }, 500);
        //     },
        // );
    }

    private getComment(comment: string): string {
        const ci = this.store.selectSnapshot(BaseState.getCompanyInfo);
        const wi = this.store.selectSnapshot(BaseState.getWidgetInfo);
        const ri = this.store.selectSnapshot(BaseState.getReservationInfo);
        const data: string[] = [];
        if (ci) {
            data.push(ci.name, ci.taxid);
        }

        if (ri.isExternal) {
            data.push(wi.origin);
        }

        comment ? data.push(comment) : undefined;
        return data.join(';');
    }

    private payment(price: number, customer: Customer, prereservationId: string, token?: string): Observable<any> {
        const reservationInfo = this.store.selectSnapshot(BaseState.getReservationInfo);
        const selectedSlot = this.store.selectSnapshot(BaseState.getSelectedSlot);
        const widgetInfo = this.store.selectSnapshot(BaseState.getWidgetInfo);
        // const service: Service = ctx.getState().reservationInfo.service;
        // const service: Service = ctx.getState().reservationInfo.;
        // const price = ctx.getState().selectedSlot?.price;

        //https://test-booking.eambulanta.si/public/booking-widget/confirmed?guid=dda76d9d-4c00-4ae6-8795-512d43d49ac1
        const successUrl = `${environment.appUrl}/public/booking-widget/confirmed?guid=${prereservationId}&isExternal=${reservationInfo?.isExternal}`;
        const errorUrl = `${environment.appUrl}/public/booking-widget/notification?value=error_1`;
        const cancelUrl = `${environment.appUrl}/public/booking-widget/notification?value=cancel_1`;

        const paymentInfo = {
            amount: price,
            code: reservationInfo?.coupon?.isValid ? reservationInfo?.coupon?.code : undefined,
            hash: selectedSlot?.hash || selectedSlot?.originalHash,
        } as PaymentInfo;

        const customerInfo = {
            firstName: customer.name,
            lastName: customer.surname,
            email: customer.email,
        } as CustomerInfoDto;

        const redirectInfo = {
            successUrl: successUrl,
            cancelUrl: cancelUrl,
            errorUrl: errorUrl,
        } as PaymentRedirectInfoDto;

        return of(widgetInfo).pipe(
            switchMap(widgetInfo => {
                const pgwProvider = widgetInfo?.pgwProvider;
                if (widgetInfo?.pgw && pgwProvider === PaymentProviderType.STRIPE) {
                    this.store.dispatch(
                        new Navigate(['public/booking-widget/payment-stripe'], undefined, {
                            queryParamsHandling: 'merge',
                            replaceUrl: true,
                        }),
                    );
                    return of({ url: '' } as ExecutePaymentResponseDto);
                } else if (widgetInfo?.pgw && pgwProvider === PaymentProviderType.BANKART) {
                    return this.executeRedirectPayment(prereservationId, token, paymentInfo, customerInfo, redirectInfo);
                } else if (widgetInfo?.pgw) {
                    // default, if no provider is set; backwards compatibility
                    return this.executeRedirectPayment(prereservationId, token, paymentInfo, customerInfo, redirectInfo);
                } else {
                    return of(null);
                }
            }),
        );
    }

    @Action(BaseStateActions.Widget.SetCustomer)
    public SetCustomer(ctx: StateContext<BaseStateModel>, { customer }: BaseStateActions.Widget.SetCustomer) {
        ctx.setState(
            patch<BaseStateModel>({
                customer: customer,
            }),
        );
    }

    @Action(BaseStateActions.Widget.SetService)
    public SetService(ctx: StateContext<BaseStateModel>, { service }: BaseStateActions.Widget.SetService) {
        ctx.setState(
            patch<BaseStateModel>({
                reservationInfo: patch({
                    service: service,
                    maskedServiceId: service?.maskedId,
                    maskedServiceName: service?.name,
                }),
            }),
        );
        if (service.directBooking) {
            this.store.dispatch(
                new Navigate(['public/booking-widget/term'], undefined, {
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                }),
            );
        } else {
            this.store.dispatch(
                new Navigate(['public/booking-widget/waiting-queue'], undefined, {
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                }),
            );
        }
    }

    @Action(BaseStateActions.Common.SetCoupon)
    public SetCoupon(ctx: StateContext<BaseStateModel>, { coupon }: BaseStateActions.Common.SetCoupon) {
        // const price: number = ctx.getState().reservationInfo.service.price || ctx.getState().selectedSlot.price;

        ctx.setState(
            patch<BaseStateModel>({
                selectedSlot: patch({
                    discountValue: coupon?.price,
                    hash: coupon?.hash,
                }),
                reservationInfo: patch({
                    coupon: coupon,
                }),
            }),
        );
    }

    @Action(BaseStateActions.Common.SetCompanyInfo)
    public SetCompanyInfo(ctx: StateContext<BaseStateModel>, { ci }: BaseStateActions.Common.SetCompanyInfo) {
        ctx.setState(
            patch<BaseStateModel>({
                companyInfo: ci,
            }),
        );
    }

    // private calculateDiscount(price: number, coupon: Coupon): number {
    //     if (coupon?.discountAmount) {
    //         return coupon.discountAmount < price ? price - coupon.discountAmount : 0;
    //     } else if (coupon?.discountPercent) {
    //         let priceN = Number(price);
    //         let discountN = Number(coupon.discountPercent);
    //         let afterDiscount = priceN - (priceN * discountN) / 100;
    //         return this.helper.precisionRound(afterDiscount, 2);
    //     }
    //     return price;
    // }
    @Action(BaseStateActions.Common.BookSelectedSlot)
    public BookSelectedSlot(ctx: StateContext<BaseStateModel>, { slot, additionaData }: BaseStateActions.Common.BookSelectedSlot) {
        if (!slot) {
            slot = ctx.getState().selectedSlot;
        }
        const resInf: ReservationInfo = ctx.getState().reservationInfo;
        const widgetInfo: WidgetInfo = ctx.getState().widgetInfo;
        const originUrl = widgetInfo?.origin ? widgetInfo?.origin : resInf?.bsId ? undefined : 'SELF-EMAIL';
        const data: MaskedPrereservationRequest = {
            // Start HF MD-3348
            maskedContractorId: widgetInfo?.maskedCinfoId || resInf?.maskedCinfoId || resInf?.maskedContractorInfoId,
            maskedCinfoId: widgetInfo?.maskedCinfoId || resInf?.maskedCinfoId,
            // End HF
            maskedSubcontractorId: slot.maskedSubcontractorId,
            maskedServiceId: resInf.maskedServiceId,
            maskedCustomerId: resInf.maskedCustomerId,
            maskedBsId: widgetInfo?.maskedBsId,
            from: slot.start,
            to: slot.end,
            forceUpdate: false,
            originUrl: originUrl,
            reservationType: resInf?.bsId ? ReservationType.ASISTENT : ReservationType.WIDGET,
            asset: {
                id: slot.assetId,
                externalId: slot.assetId,
            },
            telemed: resInf?.service?.telemed,
            phoneCall: resInf?.service?.phoneCall,
            bsid: resInf?.bsId,
            caseId: resInf?.caseId,
            service: {
                name: resInf?.service?.name,
                price: slot?.price,
            },
            widgetData: JSON.stringify(additionaData),
            widgetId: resInf.token,
            allowHolidayBooking: true,
            scheduleId: slot?.scheduleId,
            noOfReservations: 1, // TODO hardcoded & apply only for heliant bpi
        };
        if (widgetInfo?.maskedBsId && !resInf.isExternal) {
            data.reservationType = ReservationType.WIDGET_VOUCHER;
        }

        if (widgetInfo && (<WidgetData>widgetInfo?.data)?.reservationType) {
            data.reservationType = (<WidgetData>widgetInfo.data).reservationType;
        }

        this.reservationsService
            .createMaskedPrereservation(data)
            .pipe(
                tap((reservation: ReservationConfirmationRequest) => {
                    //TODO REFACTOR
                    if (resInf.maskedBsId && resInf.maskedBsId != 'undefined') {
                        if (resInf.maskedContractorId) {
                            reservation.maskedContractorId = resInf.maskedContractorId;
                        }
                        reservation.maskedBsId = resInf.maskedBsId;
                    }
                    ctx.setState(
                        patch<BaseStateModel>({
                            prereservation: reservation,
                        }),
                    );
                }),
                mergeMap((reservation: ReservationConfirmationRequest) => {
                    if (resInf.isExternal && this.hasNoTokenOrHasSelfPaymentWithToken(resInf)) {
                        return this.reservationsService.widgetVendorConfirmPrereservation(reservation, resInf?.token).pipe(map(() => reservation));
                    }
                    return of(reservation);
                }),
                mergeMap((reservation: ReservationConfirmationRequest) => {
                    if (this.isSelfPayment(resInf)) {
                        return this.payment(resInf?.selfPayment, resInf?.customer, reservation?.prereservationId, resInf?.token);
                    }
                    return of(undefined);
                }),
            )
            .subscribe(() => {
                if (!this.isSelfPayment(resInf)) {
                    this.store.dispatch(
                        new Navigate(['public/booking-widget/preview'], undefined, {
                            queryParamsHandling: 'merge',
                            replaceUrl: true,
                        }),
                    );
                }
            });
    }

    @Action(BaseStateActions.Common.OnSelectedSlot)
    public OnSelectedSlot(ctx: StateContext<BaseStateModel>, { slot }: BaseStateActions.Common.OnSelectedSlot) {
        ctx.setState(
            patch<BaseStateModel>({
                selectedSlot: slot,
                reservationInfo: patch({
                    startDate: slot.start,
                }),
            }),
        );

        if (ctx.getState().modeType == ModeType.WIDGET) {
            this.store.dispatch(
                new Navigate(['public/booking-widget/additional-info'], undefined, {
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                }),
            );
        } else if (ctx.getState().modeType == ModeType.RESERVATION) {
            this.store.dispatch([
                new BaseStateActions.Widget.SetCustomer(ctx.getState().reservationInfo.customer),
                new Navigate(['public/booking-widget/additional-info'], undefined, {
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                }),
            ]);
            // TODO
            // if (ctx.getState().reservationInfo.customer) {
            //     this.store.dispatch(new BaseStateActions.Common.BookSelectedSlot(slot));
            // } else {
            //     this.store.dispatch(
            //         new Navigate(['public/booking-widget/additional-info'], undefined, {
            //             queryParamsHandling: 'merge',
            //             replaceUrl: true,
            //         }),
            //     );
            // }
        }
    }

    @Action(BaseStateActions.Common.OnSelectedWQ)
    public OnSelectedWQ(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.OnSelectedWQ) {
        // ctx.setState(
        //     patch<BaseStateModel>({
        //         selectedSlot: slot,
        //         reservationInfo: patch({
        //             startDate: slot.start,
        //         }),
        //     }),
        // );

        const maskedContractorId = ctx.getState().reservationInfo?.maskedContractorId;
        const maskedServiceId = ctx.getState().reservationInfo?.maskedServiceId;

        return this.comonRest.getSubcontractors(maskedContractorId, maskedServiceId).pipe(
            map(subc => {
                if (subc.length > 0) {
                    return subc[0]?.maskedId == 'UNDEFINED' ? undefined : subc[0].maskedId;
                }
                return undefined;
            }),
            tap(maskedSubcontractorId => {
                ctx.setState(
                    patch<BaseStateModel>({
                        reservationInfo: patch({
                            maskedSubcontractorId: maskedSubcontractorId,
                        }),
                    }),
                );

                if (ctx.getState().modeType == ModeType.WIDGET) {
                    this.store.dispatch(
                        new Navigate(['public/booking-widget/additional-info'], undefined, {
                            queryParamsHandling: 'merge',
                            replaceUrl: true,
                        }),
                    );
                } else if (ctx.getState().modeType == ModeType.RESERVATION) {
                    // this.store.dispatch(new BaseStateActions.Common.BookSelectedSlot(slot));
                    this.toast.warning('Not implemented yet');
                }
                // debugger;
            }),
        );
        // .subscribe((maskedSubcontracorId) => {

        // });
        //localhost:9090/booking/patient/api/contractors/cHB-6yBFkBzvhA4shOk-r4M/services/MhlgNvOHh%2BkOMq77upnWWg%3D%3D/subcontractors
    }

    @Action(BaseStateActions.Common.SetPrivacyPolicy)
    public SetPrivacyPolicy(ctx: StateContext<BaseStateModel>, { policy }: BaseStateActions.Common.SetPrivacyPolicy) {
        ctx.setState(
            patch<BaseStateModel>({
                isPrivacyPolicy: policy,
            }),
        );
    }

    @Action(BaseStateActions.Common.GetContractor)
    public GetContractor(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.GetContractor) {
        const contractorId = ctx.getState().reservationInfo?.maskedContractorId;
        const isExternal = ctx.getState().reservationInfo?.isExternal;
        return this.comonRest.getContractor(contractorId, isExternal).pipe(
            tap((c: Contractor) => {
                ctx.setState(
                    patch<BaseStateModel>({
                        reservationInfo: patch({
                            contractor: c,
                        }),

                        contractor: c,
                    }),
                );

                // ctx.patchState({
                //     reservationInfo:
                //     {
                //         contractor: c,
                //     },
                //     contractor: c,
                // });
            }),
        );
    }

    @Action(BaseStateActions.Common.ExecutePayment)
    public ExecutePayment(ctx: StateContext<BaseStateModel>, { customer }: BaseStateActions.Common.ExecutePayment) {
        if (ctx.getState().modeType == ModeType.WIDGET) {
            return this.store.dispatch([new BaseStateActions.Widget.CreateReservation(new Customer().deserialize(customer), true)]);
        } else {
            return this.store.dispatch([
                new BaseStateActions.Common.BookSelectedSlot(undefined, {
                    privacyPolicy: true,
                }),
            ]);
        }
    }

    private executeRedirectPayment(
        prereservationId: string,
        token: string,
        paymentInfo: PaymentInfo,
        customer: CustomerInfoDto,
        redirectInfo: PaymentRedirectInfoDto,
    ) {
        const paymentRequest = {
            preReservationId: prereservationId,
            token: token,
            paymentInfo: paymentInfo,
            customer: customer,
            redirectInfo: redirectInfo,
        } as ExecutePaymentRequestDto;
        return this.pgw.executePayment(paymentRequest).pipe(
            tap((pgwResult: ExecutePaymentResponseDto) => {
                this.helper.hideLoading();

                if (pgwResult?.isPaid) {
                    this.parentIframe.toggleScroll();
                    return pgwResult;
                } else if (pgwResult?.url) {
                    this.parentIframe.toggleScroll(true);
                    window.location.href = pgwResult.url;
                    // this.store.dispatch(new Navigate([pgwResult.url]));
                } else if (pgwResult.errors) {
                    this.parentIframe.toggleScroll();
                    this.helper.hideLoading();
                    console.log(JSON.stringify(pgwResult.errors));
                }
            }),
            catchError((err: any) => {
                this.helper.hideLoading();
                console.log(JSON.stringify(err));
                return of(err);
            }),
        );
    }

    private isSelfPayment(resInf: ReservationInfo): boolean {
        return resInf?.selfPayment != undefined && resInf?.selfPayment > 0;
    }

    private hasNoTokenOrHasSelfPaymentWithToken(resInf: ReservationInfo): boolean {
        return !resInf?.token || (resInf?.token && this.isSelfPayment(resInf));
    }
}
